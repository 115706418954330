import { uploadURL } from "@/utils/consts";
export default {
  computed: {
    spin() {
      return this.$store.state.spin;
    },
    galleryImages() {
      const images = this.spin.exteriorImages
        ? [...this.spin.exteriorImages, ...this.spin.exteriorImages]
        : [];
      return (
        images?.map((i) => {
          return {
            src: uploadURL + i,
            thumbnailHeight: 100,
            thumbnailWidth: 100,
          };
        }) || []
      );
    },
  },
  methods: {
    openGallery(src) {
      this.$refs.silentbox.openOverlay({
        src: src.src,
      });
    },
  },
};
