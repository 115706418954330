<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" width="687">
    <v-card class="mx-auto shareRide" width="687" outlined>
      <h3>{{ $t("shareYourRide") }}</h3>

      <v-list-item three-line>
        <v-list-item-content>
          <v-row class="shareItem">
            <v-col cols="10">
              <v-text-field
                dense
                outlined
                @focus="$event.target.select()"
                :prefix="$t('publicURL')"
                :value="publicURL"
                ref="publicURL"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn @click="copyToClipboard('publicURL')" text rounded small>
                <v-icon>mdi-content-copy</v-icon> {{ $t("copy") }}
              </v-btn>
            </v-col>
          </v-row>

          <h3>{{ $t("embedCode") }}</h3>
          <v-tabs v-model="tab" centered fixed-tabs active>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1"> {{ $t("responsive") }} </v-tab>
            <v-tab href="#tab-2"> {{ $t("fixedWidth") }} </v-tab>
          </v-tabs>

          <v-form v-if="tab === 'tab-2'">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('enterWidth')"
                    v-model="width"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('enterHeight')"
                    v-model="height"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-row class="shareItem codeEmbed">
            <v-col cols="10">
              <v-text-field
                v-model="embedCode"
                dense
                outlined
                @focus="$event.target.select()"
                :prefix="$t('embedCode')"
                readonly
                ref="embedCode"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn @click="copyToClipboard('embedCode')" text rounded small>
                <v-icon>mdi-content-copy</v-icon> {{ $t("copy") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="shareItem">
            <v-col cols="10">
              <v-text-field
                v-model="iframeCode"
                dense
                outlined
                @focus="$event.target.select()"
                :prefix="$t('iframeCode')"
                readonly
                ref="iframeCode"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn @click="copyToClipboard('iframeCode')" text rounded small>
                <v-icon>mdi-content-copy</v-icon> {{ $t("copy") }}
              </v-btn>
            </v-col>
          </v-row>

          <h3>{{ $t("shareOnSocial") }}</h3>

          <div class="socialShare">
            <v-btn rounded>
              <ShareNetwork
                style="color: white; text-decoration: none"
                network="facebook"
                :url="publicURL"
                :title="title"
              >
                Facebook <v-icon dark>mdi-facebook </v-icon>
              </ShareNetwork>
            </v-btn>

            <v-btn rounded>
              <ShareNetwork
                style="color: white; text-decoration: none"
                network="twitter"
                :url="publicURL"
                :title="title"
              >
                Twitter <v-icon dark>mdi-twitter </v-icon>
              </ShareNetwork>
            </v-btn>
          </div>

          <v-btn @click="$emit('close')" text>{{ $t("done") }}</v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <!--<v-btn color="primary" rounded block> SAVE </v-btn>-->
      </v-card-actions>
    </v-card></v-dialog
  >
</template>
<script>
export default {
  name: "ShareModal",
  props: ["value"],
  mixins: [],
  data: () => ({
    tab: "tab-1",
    width: 1273,
    height: 766,
    shareableURL: "https://share.spinmyride.net",
    playerURL: "https://player.spinmyride.net",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    spin() {
      return this.$store.state.spin;
    },
    title() {
      return `Spin my ride ${this.spin.brand.toUpperCase()} ${this.spin.model.toUpperCase()}`;
    },
    publicURL() {
      return `${this.shareableURL}/spins/${this.$route.params.id}`;
    },
    embedCode() {
      if (this.tab === "tab-1") {
        return `<div><spin-my-ride-widget data-id="${this.$route.params.id}"></spin-my-ride-widget></div><script src="${this.playerURL}/js/app.js"><//script>`.replace(
          "//script",
          "/script"
        );
      }
      return `<div style="width: ${this.width}px; height: ${this.height}px"><spin-my-ride-widget data-id="${this.$route.params.id}"></spin-my-ride-widget></div><script src="${this.playerURL}/js/app.js"><//script>`.replace(
        "//script",
        "/script"
      );
    },
    iframeCode() {
      if (this.tab === "tab-1") {
        return ` <div style="position:relative;"><div style="padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;"><iframe src="${this.publicURL}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:none" allowFullScreen="true" webkitAllowFullScreen mozallowfullscreen allowFullScreen allow="accelerometer;autoplay;encrypted-media;gyroscope;xr-spatial-tracking;xr;geolocation;picture-in-picture;fullscreen"></iframe></div></div>`;
      }
      return `<iframe width="${this.width}px" height="${this.height}px" src="${this.publicURL}" style="border:none;" allowFullScreen="true" webkitAllowFullScreen mozallowfullscreen allowFullScreen allow="accelerometer;autoplay;encrypted-media;gyroscope;xr-spatial-tracking;xr;geolocation;picture-in-picture;fullscreen"></iframe>`;
    },
  },
  watch: {
    width(val) {
      this.height = Math.round(val * 1.661);
    },
  },
  methods: {
    async copyToClipboard(text) {
      this.$refs[text].focus();
      await navigator.clipboard.writeText(this[text]);
    },
  },
};
</script>


<style>
.shareRide {
  padding-top: 15px;
}
.shareRide .v-text-field__prefix {
  padding-right: 10px;
  font-weight: 600;
}
.shareRide h3 {
  color: #5f6c78b5;
  margin: 2px 0 0 16px;
  height: 35px;
  font-size: 1.125em;
  font-weight: 500;
}
.shareRide .v-list-item__content {
  padding: 0;
}
.shareRide .v-list-item__content h3 {
  margin: 20px 0 0 0;
  height: 30px;
}

.shareItem {
  height: 55px;
  margin: 0 !important;
}
.codeEmbed {
  margin-top: 15px !important;
}
.shareItem .col-2 > button {
  color: white;
  background: #1a76d2;
  height: 40px !important;
  width: 90%;
  margin-left: 10%;
}
.shareItem .col-10 {
  padding: 0;
}
.shareItem .col-2 {
  padding: 0;
}

.title_share {
  color: #87919a;
  font-size: 1em;
  padding: 0 0 17px 5px;
}
.v-btn__content i {
  font-size: 1.5em !important;
  margin-right: 3px;
}
.shareRide .v-tab--active {
  background: #e1eefe;
  border-radius: 5px 5px 0 0;
}
.shareRide .v-input__slot fieldset {
  color: #c6c6c6 !important;
}

.shareRide .v-list-item__content > button:last-of-type {
  margin-top: 20px;
}
.shareRide form .row {
  background: #e1eefe;
  border-radius: 7px;
  margin-top: -5px;
  padding: 0 11px;
  height: 87px;
}

.socialShare {
  padding-top: 5px;
}
.socialShare button {
  color: white !important;
  font-weight: bold;
}
.socialShare button:first-of-type {
  background-color: #1b76f4 !important;
  margin-right: 10px;
}
.socialShare button:nth-of-type(2) {
  background-color: #00acee !important;
}
</style>
