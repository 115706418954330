var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('share-modal',{attrs:{"value":_vm.shareModal},on:{"close":function($event){_vm.shareModal = false}}}),_c('select-background',{attrs:{"spin":_vm.spin},model:{value:(_vm.showBackgroundModal),callback:function ($$v) {_vm.showBackgroundModal=$$v},expression:"showBackgroundModal"}}),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":""},on:{"change":_vm.onPickFile}}),_c('v-navigation-drawer',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDrawer),expression:"hideDrawer"}],staticClass:"img-drawer",attrs:{"stateless":true,"absolute":"","temporary":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-container',{staticStyle:{"min-height":"434px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"add_img2",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":function($event){_vm.shareModal = _vm.checkUser(
                null,
                ['FREE'],
                ['BUSINESS', 'BUSINESS+']
              )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-share")]),_c('p',[_vm._v(_vm._s(_vm.$t("shareRide")))])],1)],1),_c('v-col',{staticClass:"add_img2",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":_vm.openPreview}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_c('p',[_vm._v(_vm._s(_vm.$t("preview")))])],1)],1),_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-icon',{on:{"click":function($event){_vm.show = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"title_1"},[_vm._v("Manage images")])]),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"loading":_vm.exportImagesLoading},on:{"click":_vm.exportImages}},[_vm._v("EXPORT IMAGES")])],1)],1),_c('h1',{staticClass:"title_2"},[_vm._v(_vm._s(_vm.$t("exterior360")))]),_c('draggable',{staticClass:"row",attrs:{"draggable":".item"},on:{"change":_vm.draggableChange},model:{value:(_vm.spin.images360),callback:function ($$v) {_vm.$set(_vm.spin, "images360", $$v)},expression:"spin.images360"}},[_vm._l((_vm.spin.images360),function(img,i){return _c('v-col',{key:img,staticClass:"item",attrs:{"cols":"4"}},[_c('v-card',[_c('v-icon',{staticClass:"del-img",attrs:{"dark":"","left":""},on:{"click":function($event){(_vm.selectedImageForDelete = {
                  idx: i,
                  image: img.split('?v=')[0],
                  type: 'images_360',
                }),
                  (_vm.showConfirmDialog = true)}}},[_vm._v("mdi-close-circle")]),_c('v-img',{staticClass:"grey darken-4",attrs:{"src":_vm.getImageURL(_vm.spin, 'images_360', 'thumbnails', img),"height":"100"},on:{"click":function($event){_vm.$emit('openImage', {
                  index: i,
                  images: _vm.spin.images360.map(function (ei) { return _vm.getImageURL(_vm.spin, 'images_360', 'resized', ei); }
                  ),
                })}}},[_c('v-icon',{staticClass:"move-img",attrs:{"dark":"","left":""}},[_vm._v("mdi-arrow-all")])],1)],1)],1)}),_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":function($event){return _vm.uploadFile($event, 'images_360')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(_vm._s(_vm.$t("addNew360Exterior").toUpperCase()))])],1)],1),_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":_vm.changeBackground}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(_vm._s(_vm.$t("changeBackground").toUpperCase()))])],1)],1),(_vm.user.package === 'BUSINESS+')?_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":_vm.buyCredits}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("buyMoreCredits", { credits: _vm.user.backgroundChangeCredits || 0, }).toUpperCase())+" ")])],1)],1):_vm._e()],2),_c('v-divider'),_c('h1',{staticClass:"title_2"},[_vm._v(_vm._s(_vm.$t("exterior")))]),_c('v-row',[_vm._l((_vm.spin.exteriorImages),function(img,i){return _c('v-col',{key:img,attrs:{"cols":"4"}},[_c('v-card',[_c('v-icon',{staticClass:"del-img",attrs:{"dark":"","left":""},on:{"click":function($event){(_vm.selectedImageForDelete = {
                  idx: i,
                  image: img,
                  type: _vm.getFieldName('exterior'),
                }),
                  (_vm.showConfirmDialog = true)}}},[_vm._v("mdi-close-circle")]),_c('v-img',{staticClass:"grey darken-4",attrs:{"src":_vm.getImageURL(_vm.spin, 'exterior', 'thumbnails', img),"height":"100"},on:{"click":function($event){_vm.$emit('openImage', {
                  index: i,
                  images: _vm.spin.exteriorImages.map(function (ei) { return _vm.getImageURL(_vm.spin, 'exterior', 'resized', ei); }
                  ),
                })}}})],1)],1)}),_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":function($event){return _vm.uploadFile($event, 'exterior')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(_vm._s(_vm.$t("addNewExterior").toUpperCase()))])],1)],1)],2),_c('v-divider'),_c('h1',{staticClass:"title_2"},[_vm._v(_vm._s(_vm.$t("interior360")))]),_c('v-row',[_vm._l((_vm.spin.panoramaImages),function(img,i){return _c('v-col',{key:img,attrs:{"cols":"4"}},[_c('v-card',[_c('v-icon',{staticClass:"del-img",attrs:{"dark":"","left":""},on:{"click":function($event){(_vm.selectedImageForDelete = {
                  image: img,
                  type: _vm.getFieldName('panorama'),
                }),
                  (_vm.showConfirmDialog = true)}}},[_vm._v("mdi-close-circle")]),_c('v-img',{staticClass:"grey darken-4",attrs:{"src":_vm.getImageURL(_vm.spin, 'panorama', 'thumbnails', img),"height":"100"},on:{"click":function($event){_vm.$emit('openImage', {
                  index: i,
                  images: _vm.spin.panoramaImages.map(function (ei) { return _vm.getImageURL(_vm.spin, 'panorama', 'resized', ei); }
                  ),
                })}}})],1)],1)}),_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":function($event){return _vm.uploadFile($event, 'panorama')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(_vm._s(_vm.$t("addNew360Interior")))])],1)],1)],2),_c('v-divider'),_c('h1',{staticClass:"title_2"},[_vm._v(_vm._s(_vm.$t("interior")))]),_c('v-row',[_vm._l((_vm.spin.interiorImages),function(img,i){return _c('v-col',{key:img,attrs:{"cols":"4"}},[_c('v-card',[_c('v-icon',{staticClass:"del-img",attrs:{"dark":"","left":""},on:{"click":function($event){(_vm.selectedImageForDelete = {
                  image: img,
                  type: _vm.getFieldName('interior'),
                }),
                  (_vm.showConfirmDialog = true)}}},[_vm._v("mdi-close-circle")]),_c('v-img',{staticClass:"grey darken-4",attrs:{"src":_vm.getImageURL(_vm.spin, 'interior', 'thumbnails', img),"height":"100"},on:{"click":function($event){_vm.$emit('openImage', {
                  index: i,
                  images: _vm.spin.interiorImages.map(function (ei) { return _vm.getImageURL(_vm.spin, 'interior', 'resized', ei); }
                  ),
                })}}})],1)],1)}),_c('v-col',{staticClass:"add_img",attrs:{"cols":"4"}},[_c('v-card',{on:{"click":function($event){return _vm.uploadFile($event, 'interior')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_c('p',[_vm._v(_vm._s(_vm.$t("addNewInterior").toUpperCase()))])],1)],1)],2),_c('v-divider'),_c('h1',{staticClass:"title_1"},[_vm._v(_vm._s(_vm.$t("modifyLogo")))]),_c('v-row',{staticClass:"logo-select"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{attrs:{"loading":_vm.loadingLogo,"label":_vm.$t('fileInput'),"filled":"","prepend-icon":"mdi-camera","accept":"image/png"},on:{"change":function($event){return _vm.onPickFile('logo')},"click":function($event){return _vm.checkUser($event, ['FREE', 'BUSINESS'], ['BUSINESS+'])}},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.spin.logo && _vm.spin.logo.length)?_c('v-img',{staticClass:"grey darken-4",attrs:{"src":_vm.getImageURL(
                _vm.spin,
                'logo',
                'thumbnails',
                _vm.spin.logo ? _vm.spin.logo[0] : ''
              ),"height":"125"}}):_vm._e()],1)],1)],1)],1),_c('confirm-modal',{on:{"confirm":_vm.confirm},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }