<template>
  <edit />
</template>
<script>
import Edit from "@/components/spins/Edit";
//import Edit from "@/components/spins/Panorama2";
export default {
  components: {
    Edit,
    //Panorama,
  },
};
</script>