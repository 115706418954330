<template>
  <v-container style="max-width: 1285px">
    <v-row class="text-center">
      <v-col cols="12" style="padding: 0">
        <div
          id="panorama"
          class="vue-pano-viewer"
          :style="size"
          ref="viewer"
          @mousedown="getPosition"
          @mouseup="holding = false"
        />
        <v-menu
          v-model="showMenu"
          :position-x="screenPosition.x"
          :position-y="screenPosition.y"
          absolute
          style="max-width: 600px"
        >
          <v-card class="mx-auto kartica1" max-width="500" outlined>
            <v-card-actions>
              <v-btn rounded color="primary" @click="addHotspotModal = true">
                {{ $t('createNewHotspot') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <create-hotspot-modal
      :value="addHotspotModal"
      @selectImage="selectImageModal = true"
      @addHotspot="addHotspot"
      @close="addHotspotModal = false"
    ></create-hotspot-modal>
    <edit-hotspot-modal
      v-if="selectedPin"
      :value="dialog"
      :selected-pin="selectedPin"
      hotspotType="interior"
      @editHotspot="editHotspot"
      @deleteHotspot="deleteHotspot"
      @close="(dialog = false), (selectedPin = null)"
    ></edit-hotspot-modal>
    <select-image-modal
      :value="selectImageModal"
      :images="spin.interiorImages"
      :spin="spin"
      hotspotType="interior"
      @selectImage="selectImage"
      @close="selectImageModal = false"
    ></select-image-modal>
  </v-container>
</template>
<script>
import "pannellum/src/js/libpannellum.js";
import "pannellum/src/js/pannellum.js";
//import "pannellum";
import "pannellum/src/css/pannellum.css";
import { updatePanoHotspot } from "@/api";
import CreateHotspotModal from "@/components/common/modals/CreateHotspot";
import EditHotspotModal from "@/components/common/modals/EditHotspot";
import SelectImageModal from "@/components/common/modals/SelectImage";
import { v4 as uuidv4 } from "uuid";
import imageURLs from "@/mixins/imageURLs";

export default {
  components: {
    CreateHotspotModal,
    EditHotspotModal,
    SelectImageModal,
  },
  mixins: [imageURLs],
  data: () => ({
    viewer: null,
    panorama: null,
    holding: false,
    canvas: null,
    showMenu: false,
    screenPosition: {
      x: 0,
      y: 0,
    },
    event: null,
    addHotspotModal: false,
    dialog: false,
    selectedPin: null,
    selectImageModal: false,
    hotspotSelectedImage: null,
    movePin: false,
  }),
  computed: {
    spin() {
      return this.$store.state.spin;
    },
    pins() {
      return this.$store.state.pins;
    },
    panoPins() {
      return this.$store.state.panoPins.map((p) => {
        return {
          ...p,
          cssClass: p.cssClass || "green-hotspot",
          image: null,
          clickHandlerArgs: p.id,
          clickHandlerFunc: () => {
            this.selectedPin = p;
          },
          dragHandlerFunc: () => {
            this.movePin = new Date().valueOf();
            this.holding = true;
          },
          dragEndHandlerFunc: async () => {
            if (new Date().valueOf() - this.movePin < 100) {
              this.holding = false;
            }
            await updatePanoHotspot(this.spin._id, this.panoPins);
          },
          draggable: true,
          //...(p.img && { image: this.getImageURL(this.spin, 'interior', 'thumbnails', p.img) }),
        };
      });
    },
    size() {
      const { width, height } = this.canvas.getBoundingClientRect();
      if (!width || !height) return "height: 720px; width: 1280px";
      return `height: ${height}px; width: ${width}px`;
    },
  },
  created() {
    this.canvas = document.getElementById("canvas");
  },
  mounted() {
    this.viewer = window.pannellum.viewer("panorama", {
      type: "equirectangular",
      panorama: this.getImageURL(
        this.spin,
        "panorama",
        null,
        this.spin.panoramaImages[0]
      ),
      autoLoad: true,
      hotSpots: this.panoPins,
      hfov: 120,
      startOrientation: true,
    });
  },
  methods: {
    async editHotspot(data) {
      const pin = this.panoPins.find(
        (pin) => pin.pitch === data.pitch && pin.yaw === data.yaw
      );
      pin.text = data.text;
      pin.img = data.image;
      this.viewer.removeHotSpot(pin.id);
      this.viewer.addHotSpot(pin);
      await updatePanoHotspot(this.spin._id, this.panoPins);
      this.dialog = false;
    },
    async addHotspot(data) {
      const [pitch, yaw] = this.viewer.mouseEventToCoords(this.event);
      const { name, description } = data;
      const newHotSpot = {
        id: uuidv4(),
        pitch,
        yaw,
        cssClass: data.type === "Feature" ? "green-hotspot" : "red-hotspot",
        type: "info",
        text: name,
        img: this.hotspotSelectedImage,
        description,
      };
      this.viewer.addHotSpot({
        ...newHotSpot,
        draggable: true,
        clickHandlerArgs: newHotSpot.id,
        clickHandlerFunc: () => {
          this.selectedPin = newHotSpot;
        },
      });
      this.addHotspotModal = false;
      await updatePanoHotspot(this.spin._id, this.panoPins);
    },
    async deleteHotspot(data) {
      const pinIndex = this.panoPins.findIndex((pin) => pin.id === data.id);
      this.viewer.removeHotSpot(this.panoPins[pinIndex].id);
      this.panoPins.splice(pinIndex, 1);
      await updatePanoHotspot(this.spin._id, this.panoPins);
      this.selectedPin = null;
      this.dialog = false;
    },
    selectImage(img) {
      this.hotspotSelectedImage = img[0];
      this.selectImageModal = false;
    },
    getPosition(e) {
      if (
        e.target.className.includes(
          "pnlm-hotspot-base green-hotspot pnlm-tooltip pnlm-pointer"
        ) ||
        e.target.className.includes(
          "pnlm-hotspot-base red-hotspot pnlm-tooltip pnlm-pointer"
        )
      ) {
        this.holding = true;
        setTimeout(async () => {
          if (!this.holding) {
            this.dialog = true;
          }
        }, 150);
      } else {
        this.holding = true;
        this.event = e;
        setTimeout(async () => {
          if (!this.holding) {
            this.screenPosition = {
              x: e.pageX - window.pageXOffset,
              y: e.pageY - window.pageYOffset,
            };
            this.showMenu = true;
          }
        }, 150);
      }
    },
  },
};
</script>
<style scoped>
.vue-pano-viewer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>