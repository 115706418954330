<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('close')"
    width="600"
    scrollable
  >
    <v-card class="selectImage">
      <v-card-title>
        <span class="text-h5">{{ $t("selectImage") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4" style="width: 200px" v-for="(img, i) in images" :key="img">
              <v-img
                :src="getImageURL(spin, hotspotType, 'thumbnails', img)"
                height="112px"
                width="200px"
                :class="i === selectedImage ? 'selected' : ''"
                @click="
                  i !== selectedImage
                    ? (selectedImage = i)
                    : (selectedImage = null)
                "
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          rounded
          @click="$emit('selectImage', [images[selectedImage]])"
        >
          {{ $t("select") }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          rounded
          @click="(selectedImage = null), $emit('close')"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import imageURLs from "@/mixins/imageURLs";
export default {
  name: "NewHotspotModal",
  props: ["value", "images", "spin", "hotspotType"],
  mixins: [imageURLs],
  data: () => ({
    selectedImage: null,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped>
.selectImage {
}
.selectImage .v-card__text .container {
  padding: 0;
}
.selectImage .v-card__actions {
  padding-bottom: 15px;
}

.v-image {
  border-radius: 5px;
}
.selected {
  border: 5px solid #2278cf;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}
</style>
