<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" width="350">
    <v-form v-model="valid">
      <v-card class="mx-auto createHotspot" width="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div v-if="this.selectedImg > -1" class="text-overline mb-4">
              FRAME {{ this.selectedImg + 1 }}
            </div>

            <!-- <v-file-input label="Select image" outlined dense></v-file-input> -->
            <v-btn
              rounded
              outlined
              color="primary"
              @click="$emit('selectImage')"
              >{{ $t('selectImage') }}</v-btn
            >
            <!-- <v-img max-height="80" max-width="100%" :src="'https://spinmyride.tk/uploads/' + newHotspot.image"></v-img> -->

            <v-text-field
              v-model="newHotspot.name"
              :placeholder="$t('enterHotspotName')"
              outlined
              required
            ></v-text-field>

            <v-textarea
              v-model="newHotspot.description"
              :placeholder="$t('enterDescription')"
              maxlength="200"
              outlined
              rows="2"
            ></v-textarea>

            <v-select
              v-model="newHotspot.type"
              :items="types"
              :label="$t('hotspotType')"
              filled
            ></v-select>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color="primary" rounded block @click="addHotspot">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "NewHotspotModal",
  props: ["value", "selectedImg"],
  data: () => ({
    valid: false,
    types: ["Feature", "Alert"],
    newHotspot: {
      image: "",
      name: "",
      description: "",
      type: "Feature",
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addHotspot() {
      if (!this.valid) return;
      this.$emit("addHotspot", this.newHotspot);
      this.newHotspot = {
        image: "",
        name: "",
        description: "",
        type: "Feature",
      };
    },
  },
};
</script>


<style>
.createHotspot {
  padding-bottom: 10px;
}
.createHotspot .v-list-item__content {
}

.createHotspot .v-list-item__content > .v-image {
  border-radius: 5px;
  margin-top: 10px;
}
.createHotspot .v-list-item__content > .v-input {
  height: 65px;
  margin-top: 20px;
}
.createHotspot .v-list-item__content > .v-textarea {
  height: 75px;
  margin-top: 0;
}
.createHotspot .v-list-item__content > div:last-of-type {
  height: 65px;
  margin-top: 0;
}

.createHotspot .v-card__actions > button {
  font-size: 1em;
  font-weight: bold;
  padding: 20px 0;
}
</style>
