var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"687"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"openHotspot"},[(_vm.selectedPin.image || _vm.selectedPin.img)?_c('v-img',{attrs:{"lazy-src":_vm.getImageURL(_vm.spin, _vm.hotspotType, 'resized', _vm.selectedPin.image),"max-height":"600","max-width":"800","src":_vm.getImageURL(
          _vm.spin,
          _vm.hotspotType,
          'resized',
          _vm.selectedPin.image || _vm.selectedPin.img
        )}}):_vm._e(),_c('v-container',[_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.selectImageModal = true}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-image-edit")]),_vm._v(" "+_vm._s(_vm.$t('replaceImage')))],1),_c('br'),(_vm.hotspotType === 'interior')?_c('v-text-field',{attrs:{"placeholder":_vm.$t('hotspotName'),"label":_vm.$t('hotspotName'),"outlined":""},model:{value:(_vm.selectedPin.text),callback:function ($$v) {_vm.$set(_vm.selectedPin, "text", $$v)},expression:"selectedPin.text"}}):_c('v-text-field',{attrs:{"placeholder":_vm.$t('hotspotName'),"label":_vm.$t('hotspotName'),"outlined":""},model:{value:(_vm.selectedPin.name),callback:function ($$v) {_vm.$set(_vm.selectedPin, "name", $$v)},expression:"selectedPin.name"}}),_c('v-textarea',{attrs:{"placeholder":_vm.$t('enterDescription'),"rules":[
          function (v) { return (v || '').length <= 200 ||
            _vm.$t('descriptionTooLong'); } ],"maxlength":"201","outlined":"","rows":"2"},model:{value:(_vm.selectedPin.description),callback:function ($$v) {_vm.$set(_vm.selectedPin, "description", $$v)},expression:"selectedPin.description"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","outlined":"","rounded":""},on:{"click":function($event){return _vm.$emit('deleteHotspot', _vm.selectedPin)}}},[_vm._v(" "+_vm._s(_vm.$t('deleteHotspot'))+" ")]),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.$emit('editHotspot', _vm.selectedPin)}}},[_vm._v(" "+_vm._s(_vm.$t('saveChanges'))+" ")]),_c('v-btn',{attrs:{"depressed":"","rounded":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1),_c('select-image-modal',{attrs:{"value":_vm.selectImageModal,"images":_vm.hotspotType === 'interior' ? _vm.spin.interiorImages : _vm.spin.exteriorImages,"spin":_vm.spin,"hotspotType":_vm.hotspotType},on:{"selectImage":_vm.selectImage,"close":function($event){_vm.selectImageModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }