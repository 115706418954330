<template>
  <v-dialog v-model="show" @click:outside="show = false" width="900" scrollable>
    <v-card class="selectImage">
      <v-card-title>
        <span class="text-h5">{{ $t("selectBackground") }}</span>
      </v-card-title>
      <div v-if="loading" class="text-center" style="margin: 30px">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="3"
                style="width: 200px"
                v-for="(img, i) in allBackgrounds"
                :key="img.name"
              >
                <div
                  v-if="img.type === 'bg_color'"
                  :class="i === selectedImage ? 'selected' : 'not-selected'"
                  :style="'height: 112px; background-color:' + img.name"
                  @click="
                    i !== selectedImage
                      ? (selectedImage = i)
                      : (selectedImage = null)
                  "
                ></div>
                <v-img
                  v-else
                  :src="getImgURL(img)"
                  height="112px"
                  width="200px"
                  :class="i === selectedImage ? 'selected' : 'not-selected'"
                  @click="
                    i !== selectedImage
                      ? (selectedImage = i)
                      : (selectedImage = null)
                  "
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click="changeBackground">
            {{ $t("select") }}
          </v-btn>
          <v-btn
            color="primary"
            outlined
            rounded
            @click="(selectedImage = null), (show = false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { changeBackground } from "@/api";
import { uploadURL } from "@/utils/consts";
export default {
  name: "ChangeBackground",
  props: ["value", "spin"],
  data: () => ({
    uploadURL,
    selectedImage: null,
    loading: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    customBackgrounds() {
      return (
        this.$store.state.user.backgrounds?.map((b) => {
          return {
            custom: true,
            type: "bg_image",
            name: b,
          };
        }) || []
      );
    },
    images() {
      const i = [
        { type: "bg_color", name: "white" },
        { type: "bg_image", name: "bg1.png" },
        { type: "bg_image", name: "bg2.png" },
        { type: "bg_image", name: "bg3.png" },
        { type: "bg_image", name: "bg5.jpeg" },
      ];
      return i;
    },
    allBackgrounds() {
      return [...this.images, ...this.customBackgrounds];
    },
  },
  methods: {
    getImgURL(img) {
      if (img.custom) {
        return `${this.uploadURL}${this.user._id}/backgrounds/${img.name}`;
      }
      return `${this.uploadURL}backgrounds/${img.name}`;
    },
    async changeBackground() {
      //$emit('selectBackground', [images[selectedImage]])
      this.loading = true;
      const res = await changeBackground({
        spinId: this.spin._id,
        background: this.allBackgrounds[this.selectedImage],
      });
      if (!res.status) {
        this.loading = false;
        return this.$store.commit("SET_SNACKBAR", {
          show: true,
          text: "Error",
        });
      }
      if (res.status === 402) {
        this.loading = false;
        return this.$store.commit("SET_SNACKBAR", {
          show: true,
          text: res.error,
        });
      }
      await this.$store.dispatch("getSpin", {
        id: this.spin._id,
        type: "private",
      });
      this.$store.commit("SET_PRELOAD", true);
      this.loading = false;
      this.show = false;
    },
  },
};
</script>
<style scoped>
.selectImage .v-card__text .container {
  padding: 0;
}
.selectImage .v-card__actions {
  padding-bottom: 15px;
}

.v-image {
  border-radius: 5px;
}
.selected {
  border: 5px solid #2278cf;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}
.not-selected {
  border: 1px solid grey;
  border-radius: 5px;
}
</style>
