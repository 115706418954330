<template>
  <v-dialog v-model="value" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Confirm </v-card-title>

      <v-card-text> Are you sure you want to delete this item? </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click.native="$emit('confirm', false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click.native="$emit('confirm', true)"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmModal",
  props: ["value"],
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>