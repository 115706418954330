<template>
  <v-container style="max-width: 1285px">
    <!-- <side-menu @openGallery="openGallery"></side-menu> -->
    <v-row v-if="error"
      ><v-col
        ><h3>{{ error }}</h3></v-col
      ></v-row
    >
    <template v-else>
      <div class="currentRide">
        <v-btn @click="$router.push('/')" small outlined rounded>
          <v-icon left>mdi-arrow-left</v-icon>
        </v-btn>
        <p>{{ spin.brand }} {{ spin.model }}</p>
      </div>

      <v-card elevation="0">
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1"> {{ $t("360exterior") }} </v-tab>
          <v-tab href="#tab-2"> {{ $t("360interior") }} </v-tab>
        </v-tabs>

        <v-btn
          class="settings_btn"
          small
          rounded
          outlined
          @click.stop="drawer = !drawer"
        >
          <v-icon dark left>mdi-wrench</v-icon>{{ $t("settings") }}
        </v-btn>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="'tab-1'">
            <v-row class="text-center">
              <v-col cols="12">
                <edit-exterior />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :key="2" :value="'tab-2'">
            <edit-interior class="canvas-container"></edit-interior>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <!-- <v-navigation-drawer absolute permanent location="right" style="right: 0">
        <template v-slot:prepend>
          <v-list-item
            lines="two"
            prepend-avatar="https://randomuser.me/api/portraits/women/81.jpg"
            title="Jane Smith"
            subtitle="Logged in"
          ></v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item
            prepend-icon="mdi-home-city"
            title="Home"
            value="home"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-account"
            title="My Account"
            value="account"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-account-group-outline"
            title="Users"
            value="users"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer> -->

      <!-- settings drawer-->
      <side-menu v-model="drawer" @openImage="openImage"></side-menu>

      <CoolLightBox
        :fullscreen="true"
        :items="images"
        :index="index"
        @close="index = null"
      >
      </CoolLightBox>
    </template>
  </v-container>
</template>
<script>
import EditExterior from "./EditExterior.vue";
import EditInterior from "./EditInterior.vue";
import SideMenu from "../common/sideMenu/SideMenu.vue";
import galleryMixin from "@/mixins/sideImages.js";
import imageURLs from "@/mixins/imageURLs";

export default {
  components: {
    EditExterior,
    EditInterior,
    SideMenu,
  },
  mixins: [galleryMixin, imageURLs],
  data: () => ({
    tab: null,
    drawer: false,
    images: [],
    index: null,
    items: [
      { title: "Home", icon: "mdi-home-city" },
      { title: "My Account", icon: "mdi-account" },
      { title: "Users", icon: "mdi-account-group-outline" },
    ],
  }),
  computed: {
    spin() {
      return this.$store.state.spin;
    },
    error() {
      return this.$store.state.error;
    },
  },
  methods: {
    openImage({ index, images }) {
      this.images = images;
      this.index = index;
    },
  },
};
</script>
<style>
.currentRide {
  margin: -60px 0 0 -5px;
  position: fixed;
  z-index: 9;
  max-width: 360px;
  width: 100%;
}
.currentRide p {
  color: white;
  font-size: 1.125em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.currentRide .v-btn {
  color: white;
  float: left;
  margin: 0 7px 0 0;
  padding-right: 0 !important;
  border: 0;
  user-select: none;
}
</style>
