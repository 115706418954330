<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" width="500">
    <v-card class="hotspotList">
      <v-card-title>
        <span class="text-h5">{{ $t('chooseExistingHotspot') }}</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-container>
            <v-select
              v-model="selectedHotspot"
              :items="pins"
              label="Hotspot"
              item-text="name"
              item-value="id"
              required
              :rules="[(v) => !!v]"
            ></v-select>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded @click="addHotspot"> {{ $t("addHotspot") }} </v-btn>
        <v-btn color="primary" outlined rounded @click="$emit('close')">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewHotspotModal",
  props: ["value"],
  data: () => ({
    selectedHotspot: null,
    valid: false,
  }),
  computed: {
    pins() {
      return this.$store.state.pins;
    },
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addHotspot() {
      if (!this.valid) return;
      this.$emit("addHotspot", this.selectedHotspot);
    },
  },
};
</script>

<style>
.hotspotList .v-card__text .container {
  padding: 0;
}
.hotspotList .v-btn {
  padding: 0 15px !important;
}
.hotspotList .v-card__actions {
  padding: 16px !important;
}
</style>