<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" width="687">
    <v-card class="openHotspot">
      <v-img
        v-if="selectedPin.image || selectedPin.img"
        :lazy-src="getImageURL(spin, hotspotType, 'resized', selectedPin.image)"
        max-height="600"
        max-width="800"
        :src="
          getImageURL(
            spin,
            hotspotType,
            'resized',
            selectedPin.image || selectedPin.img
          )
        "
      ></v-img>

      <v-container>
        <v-btn
          @click="selectImageModal = true"
          rounded
          outlined
          color="primary"
        >
          <v-icon dark left>mdi-image-edit</v-icon>
          {{ $t('replaceImage') }}</v-btn
        >
        <br />
        <v-text-field
          v-if="hotspotType === 'interior'"
          :placeholder="$t('hotspotName')"
          :label="$t('hotspotName')"
          outlined
          v-model="selectedPin.text"
        ></v-text-field>
        <v-text-field
          v-else
          :placeholder="$t('hotspotName')"
          :label="$t('hotspotName')"
          outlined
          v-model="selectedPin.name"
        ></v-text-field>
        <v-textarea
          v-model="selectedPin.description"
          :placeholder="$t('enterDescription')"
          :rules="[
            (v) =>
              (v || '').length <= 200 ||
              $t('descriptionTooLong'),
          ]"
          maxlength="201"
          outlined
          rows="2"
        ></v-textarea>
        <!--<v-file-input label="Replace image" outlined dense> </v-file-input>-->
      </v-container>

      <v-card-actions>
        <v-btn
          color="red"
          outlined
          rounded
          @click="$emit('deleteHotspot', selectedPin)"
        >
          {{ $t('deleteHotspot') }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          @click="$emit('editHotspot', selectedPin)"
        >
          {{ $t('saveChanges') }}
        </v-btn>
        <v-btn depressed rounded @click="$emit('close')"> {{ $t('cancel') }} </v-btn>
      </v-card-actions>
    </v-card>
    <select-image-modal
      :value="selectImageModal"
      :images="
        hotspotType === 'interior' ? spin.interiorImages : spin.exteriorImages
      "
      :spin="spin"
      :hotspotType="hotspotType"
      @selectImage="selectImage"
      @close="selectImageModal = false"
    ></select-image-modal>
  </v-dialog>
</template>
<script>
import SelectImageModal from "@/components/common/modals/SelectImage";
import { uploadURL } from "@/utils/consts";
import imageURLs from "@/mixins/imageURLs";
export default {
  name: "NewHotspotModal",
  components: {
    SelectImageModal,
  },
  mixins: [imageURLs],
  props: ["value", "selectedPin", "hotspotType"],
  data: () => ({
    uploadURL,
    selectImageModal: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    spin() {
      return this.$store.state.spin;
    },
  },
  methods: {
    selectImage(img) {
      this.selectedPin.image = img[0];
      this.selectImageModal = false;
    },
  },
};
</script>

<style>
.openHotspot {
  padding-bottom: 10px;
}
.openHotspot > .container > button {
  margin: 0 auto;
  display: block;
}

.openHotspot .v-card__actions {
  display: grid;
  grid-template-columns: 1fr auto auto;
}
.openHotspot .v-card__actions button:first-of-type {
  border: none;
  width: 164px;
}
.openHotspot .v-btn {
  padding: 0 15px !important;
}
</style>
