<template>
  <div>
    <share-modal :value="shareModal" @close="shareModal = false"></share-modal>
    <select-background
      v-model="showBackgroundModal"
      :spin="spin"
    ></select-background>
    <input
      type="file"
      ref="file"
      style="display: none"
      @change="onPickFile"
      multiple
    />
    <v-navigation-drawer
      class="img-drawer"
      v-model="show"
      :stateless="true"
      absolute
      temporary
      v-click-outside="hideDrawer"
    >
      <v-container fluid style="min-height: 434px">
        <v-row>
          <v-col cols="4" class="add_img2">
            <v-card
              @click="
                shareModal = checkUser(
                  null,
                  ['FREE'],
                  ['BUSINESS', 'BUSINESS+']
                )
              "
            >
              <v-icon left>mdi-share</v-icon>
              <p>{{ $t("shareRide") }}</p>
            </v-card>
          </v-col>
          <v-col cols="4" class="add_img2">
            <v-card @click="openPreview">
              <v-icon left>mdi-open-in-new</v-icon>
              <p>{{ $t("preview") }}</p>
            </v-card>
          </v-col>
          <v-col style="text-align: right">
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col>
            <h1 class="title_1">Manage images</h1>
          </v-col>

          <v-col align="end">
            <v-btn
              @click="exportImages"
              :loading="exportImagesLoading"
              style="margin-top: 10px"
              >EXPORT IMAGES</v-btn
            >
          </v-col>
        </v-row>

        <h1 class="title_2">{{ $t("exterior360") }}</h1>
        <draggable
          class="row"
          v-model="spin.images360"
          @change="draggableChange"
          draggable=".item"
        >
          <v-col
            cols="4"
            v-for="(img, i) in spin.images360"
            :key="img"
            class="item"
          >
            <v-card>
              <v-icon
                @click="
                  (selectedImageForDelete = {
                    idx: i,
                    image: img.split('?v=')[0],
                    type: 'images_360',
                  }),
                    (showConfirmDialog = true)
                "
                class="del-img"
                dark
                left
                >mdi-close-circle</v-icon
              >
              <v-img
                :src="getImageURL(spin, 'images_360', 'thumbnails', img)"
                @click="
                  $emit('openImage', {
                    index: i,
                    images: spin.images360.map((ei) =>
                      getImageURL(spin, 'images_360', 'resized', ei)
                    ),
                  })
                "
                height="100"
                class="grey darken-4"
              >
                <v-icon class="move-img" dark left>mdi-arrow-all</v-icon></v-img
              >
            </v-card>
          </v-col>
          <v-col cols="4" class="add_img">
            <v-card @click="uploadFile($event, 'images_360')">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>{{ $t("addNew360Exterior").toUpperCase() }}</p>
            </v-card>
          </v-col>

          <v-col cols="4" class="add_img">
            <v-card @click="changeBackground">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>{{ $t("changeBackground").toUpperCase() }}</p>
            </v-card>
          </v-col>
          <v-col v-if="user.package === 'BUSINESS+'" cols="4" class="add_img">
            <v-card @click="buyCredits">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>
                {{
                  $t("buyMoreCredits", {
                    credits: user.backgroundChangeCredits || 0,
                  }).toUpperCase()
                }}
              </p>
            </v-card>
          </v-col>
        </draggable>

        <v-divider></v-divider>

        <h1 class="title_2">{{ $t("exterior") }}</h1>
        <v-row>
          <v-col cols="4" v-for="(img, i) in spin.exteriorImages" :key="img">
            <v-card>
              <v-icon
                @click="
                  (selectedImageForDelete = {
                    idx: i,
                    image: img,
                    type: getFieldName('exterior'),
                  }),
                    (showConfirmDialog = true)
                "
                class="del-img"
                dark
                left
                >mdi-close-circle</v-icon
              >
              <v-img
                :src="getImageURL(spin, 'exterior', 'thumbnails', img)"
                @click="
                  $emit('openImage', {
                    index: i,
                    images: spin.exteriorImages.map((ei) =>
                      getImageURL(spin, 'exterior', 'resized', ei)
                    ),
                  })
                "
                height="100"
                class="grey darken-4"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="4" class="add_img">
            <v-card @click="uploadFile($event, 'exterior')">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>{{ $t("addNewExterior").toUpperCase() }}</p>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <h1 class="title_2">{{ $t("interior360") }}</h1>
        <v-row>
          <v-col cols="4" v-for="(img, i) in spin.panoramaImages" :key="img">
            <v-card>
              <v-icon
                @click="
                  (selectedImageForDelete = {
                    image: img,
                    type: getFieldName('panorama'),
                  }),
                    (showConfirmDialog = true)
                "
                class="del-img"
                dark
                left
                >mdi-close-circle</v-icon
              >
              <v-img
                :src="getImageURL(spin, 'panorama', 'thumbnails', img)"
                @click="
                  $emit('openImage', {
                    index: i,
                    images: spin.panoramaImages.map((ei) =>
                      getImageURL(spin, 'panorama', 'resized', ei)
                    ),
                  })
                "
                height="100"
                class="grey darken-4"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="4" class="add_img">
            <v-card @click="uploadFile($event, 'panorama')">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>{{ $t("addNew360Interior") }}</p>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <h1 class="title_2">{{ $t("interior") }}</h1>
        <v-row>
          <v-col cols="4" v-for="(img, i) in spin.interiorImages" :key="img">
            <v-card>
              <v-icon
                @click="
                  (selectedImageForDelete = {
                    image: img,
                    type: getFieldName('interior'),
                  }),
                    (showConfirmDialog = true)
                "
                class="del-img"
                dark
                left
                >mdi-close-circle</v-icon
              >
              <v-img
                :src="getImageURL(spin, 'interior', 'thumbnails', img)"
                @click="
                  $emit('openImage', {
                    index: i,
                    images: spin.interiorImages.map((ei) =>
                      getImageURL(spin, 'interior', 'resized', ei)
                    ),
                  })
                "
                height="100"
                class="grey darken-4"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="4" class="add_img">
            <v-card @click="uploadFile($event, 'interior')">
              <v-icon left>mdi-plus-circle</v-icon>
              <p>{{ $t("addNewInterior").toUpperCase() }}</p>
            </v-card>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <h1 class="title_1">{{ $t("modifyLogo") }}</h1>

        <v-row class="logo-select">
          <v-col cols="8">
            <v-file-input
              v-model="images"
              @change="onPickFile('logo')"
              @click="checkUser($event, ['FREE', 'BUSINESS'], ['BUSINESS+'])"
              :loading="loadingLogo"
              :label="$t('fileInput')"
              filled
              prepend-icon="mdi-camera"
              accept="image/png"
            ></v-file-input>
          </v-col>

          <v-col cols="4">
            <v-img
              v-if="spin.logo && spin.logo.length"
              :src="
                getImageURL(
                  spin,
                  'logo',
                  'thumbnails',
                  spin.logo ? spin.logo[0] : ''
                )
              "
              height="125"
              class="grey darken-4"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <confirm-modal
      v-model="showConfirmDialog"
      @confirm="confirm"
    ></confirm-modal>
  </div>
</template>
<script>
import {
  uploadImages,
  updateImages,
  deleteImage,
  addWatermark,
  updateHotspot,
  exportImages,
} from "@/api";
import imageURLs from "@/mixins/imageURLs";
import packageLimits from "@/mixins/packageLimits";
import ShareModal from "@/components/common/modals/Share";
import ConfirmModal from "@/components/common/modals/Confirm";
import draggable from "vuedraggable";
import SelectBackground from "@/components/common/modals/SelectBackground.vue";
import { shopURL, uploadURL } from "@/utils/consts";

export default {
  props: ["value"],
  components: {
    ShareModal,
    ConfirmModal,
    draggable,
    SelectBackground,
  },
  mixins: [imageURLs, packageLimits],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    spin() {
      return this.$store.state.spin;
    },
    pins: {
      get() {
        return this.$store.state.pins;
      },
      set(val) {
        this.$store.commit("SET_PINS", val);
      },
    },
  },
  data() {
    return {
      modal_img: false,
      shareModal: false,
      selectedImg: "",
      images: [],
      imgNames: [],
      uploadType: null,
      selectedImageForDelete: null,
      showConfirmDialog: false,
      loadingLogo: false,
      showBackgroundModal: false,
      shopURL,
      uploadURL,
      exportImagesLoading: false,
    };
  },
  methods: {
    hideDrawer(e) {
      if(e.target.className === "v-overlay__scrim") {
        // this.$emit('hideDrawer',false);
        this.show = false
      }
    },
    getFieldName(type) {
      switch (type) {
        case "interior":
          return "interiorImages";
        case "exterior":
          return "exteriorImages";
        case "panorama":
          return "panoramaImages";
        case "images_360":
          return "images360";
        default:
          return "logo";
      }
    },
    buyCredits() {
      window.open(`${this.shopURL}product/credits/`, "_blank");
    },
    async changeBackground() {
      let allowAction = this.checkUser(
        null,
        ["FREE", "BUSINESS"],
        ["BUSINESS+"]
      );
      if (allowAction) {
        if (this.spin.backgroundChanged) {
          return this.$store.commit("SET_SNACKBAR", {
            text: this.$t("alreadyChangedBackground"),
            show: true,
          });
        }

        if (this.user.backgroundChangeCredits > 0) {
          this.showBackgroundModal = true;
        } else {
          this.$store.commit("SET_SNACKBAR", {
            text: this.$t("outOfCredits"),
            show: true,
          });
        }
      }
    },
    async exportImages() {
      this.exportImagesLoading = true;
      const res = await exportImages({
        userId: this.user._id,
        spinId: this.spin._id,
      });
      if (res.status) {
        window.open(
          `${this.uploadURL}${this.user._id}/${this.spin._id}/images.zip`,
          "_blank"
        );
      }
      this.exportImagesLoading = false;
    },
    async draggableChange({ moved: { newIndex, oldIndex } }) {
      await updateImages(this.spin._id, {
        images: this.spin.images360.map(img => img.split("?v=")[0]),
        type: "images360",
      });

      //update hotspots
      const oldPins = this.pins.filter((pin) => pin.imgIdx === oldIndex);
      const otherPins =
        newIndex < oldIndex
          ? this.pins.filter(
              (pin) => pin.imgIdx >= newIndex && pin.imgIdx < oldIndex
            )
          : this.pins.filter(
              (pin) => pin.imgIdx > oldIndex && pin.imgIdx <= newIndex
            );

      oldPins.forEach((pin) => (pin.imgIdx = newIndex));
      otherPins.forEach((pin) =>
        newIndex < oldIndex ? pin.imgIdx++ : pin.imgIdx--
      );

      await updateHotspot(this.spin._id, this.pins);

      this.$store.commit("SET_PRELOAD", true);
    },
    openPreview() {
      window.open(window.location.href + "/preview", "_blank");
    },
    async confirm(data) {
      if (data) {
        const payload = {
          ...this.selectedImageForDelete,
          userId: this.spin.userId,
        };
        await deleteImage(this.spin._id, payload);

        if (payload.type === "images360") {
          this.pins = this.pins.filter((pin) => pin.imgIdx === payload.idx);
          console.log(this.pins);

          this.pins
            .filter((pin) => pin.imgIdx >= payload.idx)
            .forEach((pin) => pin.imgIdx--);
          await updateHotspot(this.spin._id, this.pins);
        }

        await this.$store.dispatch("getSpin", {
          id: this.spin._id,
          type: "private",
        });
        this.$store.commit("SET_PRELOAD", true);
      }
      this.showConfirmDialog = false;
      this.selectedImageForDelete = null;
    },
    openImage(spin, type, size, img) {
      this.selectedImg = this.getImageURL(spin, type, size, img);
      this.modal_img = true;
    },

    uploadFile(event, type) {
      if (!this.checkUser(event, ["FREE", "BUSINESS"], ["BUSINESS+"])) return;

      if (type === "images_360") {
        if (this.spin.images360.length === 12) {
          this.$store.commit("SET_SNACKBAR", {
            text: this.$t("exteriorImagesMax"),
            show: true,
          });
          return;
        }
      }

      this.images = [];
      this.imgNames = [];
      this.uploadType = type;
      this.$refs.file.click();
    },
    async onPickFile(event) {
      if (event !== "logo") {
        this.images = event.target.files;
      } else {
        this.uploadType = "logo";
        this.loadingLogo = true;
      }
      await this.save();
    },
    async save() {
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();

      if (this.uploadType === "logo") {
        this.imgNames.push(this.images.name);
        formData.append(this.uploadType, this.images, this.images.name);
      } else {
        Object.values(this.images).forEach((img) => {
          const imgName = img.name.replace("copy_", "").split("?v=")[0];
          this.imgNames.push(imgName);
          formData.append(this.uploadType, img, imgName);
        });
      }
      formData.append("spinId", this.spin._id);
      formData.append("userId", this.spin.userId);

      const type = this.getFieldName(this.uploadType);

      console.log(this.imgNames)

      const updateImgs = await updateImages(this.spin._id, {
        images:
          this.uploadType === "logo"
            ? [...this.imgNames]
            : [
                ...this.spin[`${type}`].map((img) => img.split("?v=")[0]),
                ...this.imgNames,
              ],
        type,
      });
      if (updateImgs) {
        const res = await uploadImages(formData);
        if (this.uploadType === "logo") {
          await addWatermark({
            spinId: this.spin._id,
            backgroundChanged: this.spin.backgroundChanged,
          });
          this.loadingLogo = false;
        }
        if (res) {
          await this.$store.dispatch("getSpin", {
            id: this.spin._id,
            type: "private",
          });
          this.$store.commit("SET_PRELOAD", true);
        }
      }
      this.imgNames = [];
      this.$store.commit("SET_LOADING", false);
    },
  },
};
</script>
<style>
.c-modal {
  position: fixed;
  top: 10vh;
  bottom: 10vh;
  right: 10vh;
  left: 10vh;
  margin: auto;
  max-width: 1280px;
  max-height: 720px;
  z-index: 10001;
  opacity: 1;
  transition: 0.6s;
}

.bg {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  opacity: 1;
  transition: 0.3s;
}
.title_1 {
  color: #5f6c78;
}
.title_2 {
  color: #87919a;
  font-size: 1em;
  margin: 18px 0 5px 0px;
  font-weight: 500;
}
.container > .v-card {
  margin-top: 10px;
}
.img-drawer {
  width: 580px !important;
}
.v-navigation-drawer__content {
  height: calc(100vh - 76px) !important;
  margin-top: 5px;
}

.img-drawer .v-divider {
  margin: 30px 0 0 0;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 11px;
}
.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background: #969ea6;
  border-radius: 10px;
  border-right: 5px solid white;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb:hover {
  background: dimgrey;
}

.logo-select .v-image {
  height: 58px !important;
  cursor: default;
}
.logo-select {
  height: 110px;
  padding: 17px 0 0 0;
}
.logo-select .col-4,
.logo-select .col-8 {
  height: 70px;
}

.settings_btn {
  float: left;
  color: #87919a !important;
  border: none;
  margin: -37px 0 0 4px;
}

.add_img {
  height: 121px;
}
.add_img .v-card {
  height: 100%;
  display: grid;
  cursor: pointer;
}
.add_img .v-card i {
  margin: 18px auto 0 auto;
  color: #1a76d2;
}
.add_img .v-card p {
  font-size: 0.85em;
  text-align: center;
  padding: 4px 10px;
  color: #1a76d2;
  user-select: none;
  font-weight: 500;
}

.add_img2 {
  height: 100px;
}
.add_img2 .v-card {
  height: 100%;
  display: grid;
  cursor: pointer;
}
.add_img2 .v-card i {
  margin: 18px auto 0 auto;
  color: #1a76d2;
}
.add_img2 .v-card p {
  font-size: 0.85em;
  text-align: center;
  padding: 4px 10px;
  color: #1a76d2;
  user-select: none;
  font-weight: 500;
}

.img-drawer .col-4 {
  padding-bottom: 10px;
  cursor: pointer;
}
.del-img {
  display: none !important;
  position: absolute !important;
  z-index: 9;
  right: 0;
  margin: 3px 7px 0 0;
  opacity: 0.8;
}
.img-drawer .col-4:hover .del-img {
  display: block !important;
}
.img-drawer .del-img:hover {
  opacity: 1 !important;
}

.move-img {
  display: none !important;
  z-index: 9;
  left: 0;
  margin: 3px 0 0 7px;
}

.img-drawer .col-4:hover .move-img {
  display: block !important;
}
</style>
