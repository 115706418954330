export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    showSnackBar(text, show) {
      this.$store.commit('SET_SNACKBAR', { text, show });
    },
    checkUser(event, notAvailableForPackages, availableForPackages) {
      if (notAvailableForPackages.includes(this.user.package)) {
        this.showSnackBar(
          this.$t('featureAvailableOnly', { package: availableForPackages.join(' & ') }),
          true
        );
        event && event.preventDefault();
        return false;
      }
      return true;
    },
  },
};
