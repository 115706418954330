<template>
  <v-container style="max-width: 1285px">
    <v-row class="text-center">
      <v-col cols="12" style="padding: 0">
        <v-layout
          v-if="loadedPercentage < 100"
          align-center
          justify-center
          column
          fill-height
        >
          <v-flex row align-center>
            <v-progress-circular
              indeterminate
              :size="50"
              color="primary"
              class=""
            ></v-progress-circular>
          </v-flex>
        </v-layout>
        <div class="canvas-container">
          <canvas
            id="canvas"
            height="720"
            width="1280"
            style="width: 100%; height: 100%"
            @click="getPosition"
          />
          <div class="prev arrow" @click="prev">
            <v-icon left>mdi-arrow-left</v-icon>
          </div>
          <div class="next arrow" @click="next">
            <v-icon left>mdi-arrow-right</v-icon>
          </div>

          <div
            class="text-center"
            v-for="(pin, i) in getPins"
            :key="pin.position.x + '_' + pin.position.y + i"
          >
            <div :class="pin.type === 'Hotspot' || pin.type === 'Feature' ? 'greendot' : 'reddot'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <font
                    @click="
                      selectedPin = pin;
                      dialog = true;
                    "
                    @mousedown="movePin = true"
                    @mouseup="handleMouseUp"
                    @mouseleave="movePin = false"
                    @mousemove="handlePinGrab($event, pin)"
                    :id="pin.id"
                    v-bind="attrs"
                    v-on="on"
                    :style="calculate(pin)"
                  ></font
                  ><!--the actual dot-->
                </template>

                <v-card style="background: white !important; color: black !important">
                  <v-img
                    v-if="pin.image"
                    :src="
                      getImageURL(spin, 'exterior', 'thumbnails', pin.image)
                    "
                    height="145"
                    width="200"
                    class="grey darken-4"
                  ></v-img>
                  <v-card-title class="subtitle-1" style="max-width: 200px">
                    {{ pin.name }}
                  </v-card-title>

                  <v-card-subtitle style="width: 200px">
                    {{ pin.description }}
                  </v-card-subtitle>
                </v-card>
              </v-tooltip>
            </div>
          </div>
          <v-menu
            v-model="showMenu"
            absolute
            :position-x="screenPosition.x"
            :position-y="screenPosition.y"
            style="max-width: 600px"
          >
            <v-card class="mx-auto kartica1" max-width="500" outlined>
              <v-card-actions>
                <v-btn rounded color="primary" @click="addHotspotModal = true">
                  {{ $t('createNewHotspot') }}
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  color="primary"
                  @click="existingHotspotModal = true"
                >
                  {{ $t('applyExistingHotspot') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <create-hotspot-modal
      :value="addHotspotModal"
      @addHotspot="addHotspot"
      @selectImage="selectImageModal = true"
      @close="addHotspotModal = false"
    ></create-hotspot-modal>
    <edit-hotspot-modal
      v-if="selectedPin"
      :value="dialog"
      :selected-pin="selectedPin"
      hotspotType="exterior"
      @editHotspot="editHotspot"
      @deleteHotspot="deleteHotspot"
      @close="(dialog = false), (selectedPin = null)"
    ></edit-hotspot-modal>
    <hotspots-list-modal
      :value="existingHotspotModal"
      @addHotspot="addExistingHotspot"
      @close="existingHotspotModal = false"
    ></hotspots-list-modal>
    <select-image-modal
      :value="selectImageModal"
      :images="spin.exteriorImages"
      :spin="spin"
      hotspotType="exterior"
      @selectImage="selectImage"
      @close="selectImageModal = false"
    ></select-image-modal>
  </v-container>
</template>
<script>
import { updateHotspot } from "@/api";
import CreateHotspotModal from "@/components/common/modals/CreateHotspot";
import EditHotspotModal from "@/components/common/modals/EditHotspot";
import HotspotsListModal from "@/components/common/modals/HotspotsList";
import SelectImageModal from "@/components/common/modals/SelectImage";
import canvasMixin from "@/mixins/canvas";
import imageURLs from "@/mixins/imageURLs";
import { v4 as uuidv4 } from "uuid";

export default {
  mixins: [canvasMixin, imageURLs],
  components: {
    CreateHotspotModal,
    EditHotspotModal,
    HotspotsListModal,
    SelectImageModal,
  },
  data: () => ({
    dialog: false,
    modal_img: false,
    addHotspotModal: false,
    existingHotspotModal: false,
    selectImageModal: false,
    hotspotSelectedImage: null,
    showMenu: false,
    showPinOptions: false,
    selectedPin: null,
    screenPosition: {
      x: 0,
      y: 0,
    },
    canvasPosition: {
      x: 0,
      y: 0,
    },
    movePin: false,
  }),
  methods: {
    next() {
      if (this.selectedImg === this.preloadedImages.length - 1) {
        this.selectedImg = 0;
      } else {
        this.selectedImg++;
      }
      this.drawImage();
    },
    prev() {
      if (this.selectedImg === 0) {
        this.selectedImg = this.preloadedImages.length - 1;
      } else {
        this.selectedImg--;
      }
      this.drawImage();
    },
    getPosition(e) {
      const { canvasPosition, screenPosition } = this.getMousePos(
        this.canvas,
        e
      );
      this.screenPosition = screenPosition;
      this.canvasPosition = canvasPosition;
      this.showMenu = true;
    },
    handlePinGrab(event, pin) {
      if (this.movePin) {
        const { canvasPosition } = this.getMousePos(this.canvas, event);
        pin.position = { ...canvasPosition };
      }
    },
    async handleMouseUp() {
      this.movePin = false;
      await updateHotspot(this.spin._id, this.pins);
    },
    async addHotspot(newHotspot) {
      const { width, height } = this.canvas.getBoundingClientRect();
      const pin = {
        id: uuidv4(),
        imgIdx: this.selectedImg,
        position: { ...this.canvasPosition },
        canvasWidth: width,
        canvasHeight: height,
        ...newHotspot,
        image: this.hotspotSelectedImage,
      };
      const pinsCopy = [...this.pins, pin];
      const res = await updateHotspot(this.spin._id, pinsCopy);
      if (res) {
        this.pins.push(pin);
      }
      this.addHotspotModal = false;
    },
    async addExistingHotspot(hotspotId) {
      const hotspot = this.pins.find((p) => p.id === hotspotId);
      const copyHotspot = {
        ...hotspot,
        imgIdx: this.selectedImg,
        position: { ...this.canvasPosition },
      };
      const pinsCopy = [...this.pins, copyHotspot];
      const res = await updateHotspot(this.spin._id, pinsCopy);
      if (res) {
        this.pins.push(copyHotspot);
      }
      this.existingHotspotModal = false;
    },
    async editHotspot(data) {
      this.pins.forEach((pin) => {
        if (pin.id === data.id) {
          pin.name = data.name;
          pin.image = data.image;
        }
      });

      await updateHotspot(this.spin._id, this.pins);
      this.dialog = false;
    },
    selectImage(img) {
      this.hotspotSelectedImage = img;
      this.selectImageModal = false;
    },
    openPinOptions(pin) {
      this.screenPosition = pin.screenPosition;
      this.showPinOptions = true;
      this.selectedPin = pin;
    },
    async deleteHotspot(data) {
      const pinIndex = this.pins.findIndex(
        (pin) => pin.id === data.id && pin.imgIdx === data.imgIdx
      );
      this.pins.splice(pinIndex, 1);
      await updateHotspot(this.spin._id, this.pins);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.red {
  background-color: red;
  animation: pulseRed 2s infinite;
}
.green {
  background-color: green;
  animation: pulseGreen 2s infinite;
}
.canvas-container {
  position: relative;
}
.arrow {
  position: absolute;
  top: 50%;
  margin-top: -1em;
  color: #fff;
  cursor: pointer;
}
.arrow.prev {
  left: 75px;
  margin-left: -1em;
}
.arrow.next {
  right: 75px;
  margin-right: -1em;
}
.arrow.prev i,
.arrow.next i {
  color: white;
  font-size: 2em;
  background: rgb(32 39 46 / 33%);
  border-radius: 40px;
  padding: 10px;
}
.arrow.prev i:hover,
.arrow.next i:hover {
  background: rgba(32 39 46 / 66%);
}
</style>
<style>
.kartica1 .v-card__actions {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 11px;
}

.kartica1 .v-card__actions {
  padding: 12px 10px;
}
.kartica1 .v-card__actions > .v-btn.v-btn {
  padding: 0 18px;
}
.kartica1 .v-card__actions > .v-btn.v-btn .v-btn__content {
  letter-spacing: 0.6px;
}

.c-modal {
  position: fixed;
  top: 10vh;
  bottom: 10vh;
  right: 10vh;
  left: 10vh;
  margin: auto;
  max-width: 1280px;
  max-height: 720px;
  z-index: 10001;
  opacity: 1;
  transition: 0.6s;
}

.bg {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  opacity: 1;
  transition: 0.3s;
}
</style>